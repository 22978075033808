import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { __DEV__ } from '@src/configuration';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

if (!__DEV__) {
  Sentry.init({
    dsn:
      'https://cd1a58c70c0e4f229db93ae13974f96d@o805212.ingest.sentry.io/5803200',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
