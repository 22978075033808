import { Action } from 'redux';

export const CHANGE_THEME = 'ui/ChangeTheme';

export type ThemeName = 'light' | 'dark' | string;

export interface SetThemeAction extends Action<typeof CHANGE_THEME> {
  payload: ThemeName;
}

export type Actions = SetThemeAction;

export type State = {
  name: ThemeName;
};
