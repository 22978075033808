import React from 'react';
import { Loading, Notification, useAuthState, useRedirect } from 'react-admin';
import { Field, Form } from 'react-final-form';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons';
import { ThemeProvider } from '@material-ui/styles';
import { useLogin, useNotify, useTranslate } from 'ra-core';

import Copyright from '@src/components/Copyright';
import useAuthenticationStyles from '@src/hooks/useAuthenticationStyles';
import useTheme from '@src/hooks/useTheme';

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}: any) => (
  <TextField
    variant="outlined"
    dir="ltr"
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const Login = () => {
  const [formLoading, setFormLoading] = React.useState(false);
  const translate = useTranslate();
  const classes = useAuthenticationStyles();
  const notify = useNotify();
  const login = useLogin();
  const redirect = useRedirect();
  const location: any = useLocation();
  const { loading, authenticated } = useAuthState();
  const handleValidate = React.useCallback(
    (values: any) => {
      const errors: any = {};
      if (!values.email) {
        errors.email = translate('ra.validation.required');
      }
      if (!values.password) {
        errors.password = translate('ra.validation.required');
      }
      return errors;
    },
    [translate]
  );

  const handleSubmit = React.useCallback(
    async (values: any) => {
      setFormLoading(true);
      try {
        const nextPath = location.state?.nextPathname ?? '/';
        if (nextPath === '/login') {
          await login(values, '/');
        } else {
          await login(values, nextPath);
        }
      } catch (error) {
        setFormLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning'
        );
      }
    },
    [location.state, login, notify]
  );

  React.useEffect(() => {
    if (!loading && authenticated) {
      redirect('/');
    }
  }, [authenticated, loading, redirect]);

  if (loading || authenticated) return <Loading />;

  return (
    <>
      <Helmet>
        <title>{translate('ra.auth.sign_in')}</title>
      </Helmet>
      <Container component="main" maxWidth="xs" className={classes.main}>
        <Avatar className={classes.icon}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" color="textPrimary">
          {translate('ra.auth.sign_in')}
        </Typography>
        <Form
          onSubmit={handleSubmit}
          validate={handleValidate}
          render={({ handleSubmit }) => (
            <>
              <form className={classes.form} onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      type="email"
                      render={renderInput}
                      label={translate('ra.auth.email')}
                      disabled={formLoading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="password"
                      type="password"
                      component={renderInput}
                      label={translate('ra.auth.password')}
                      disabled={formLoading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={formLoading}
                      fullWidth
                      className={classes.submit}
                    >
                      {formLoading && (
                        <CircularProgress size={25} thickness={2} />
                      )}
                      {translate('ra.auth.sign_in')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <Box mt={5}>
                <Copyright />
              </Box>

              <Notification />
            </>
          )}
        />
      </Container>
    </>
  );
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme: React.FC<any> = (props) => {
  const theme = useTheme();

  return (
    // @ts-ignore
    <ThemeProvider theme={createMuiTheme(theme)}>
      <CssBaseline />
      <Login {...props} />
    </ThemeProvider>
  );
};

export default LoginWithTheme;
