import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { useTranslate } from 'ra-core';

const Copyright: React.FC = () => {
  const translate = useTranslate();

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'کپی‌رایت © '}
      <Link href="/" color="inherit">
        {translate('ra.title')}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default React.memo(Copyright);
