/* eslint react/jsx-key: 0 */

import React from 'react';
import { Route } from 'react-router-dom';

import Configuration from './Configuration';
import Dashboard from './Dashboard';

export default [
  <Route exact path="/dashboard" component={Dashboard} />,
  <Route exact path="/configuration" component={Configuration} />,
];
