import React from 'react';
import { jssPreset, StylesProvider } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';

// @ts-ignore
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const RTL: React.FC = (props) => (
  <StylesProvider jss={jss}>{props?.children}</StylesProvider>
);

export default RTL;
