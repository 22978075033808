import Icon from '@material-ui/icons/People';

import List from './List';
import Show from './Show';

export default {
  name: 'users',
  icon: Icon,
  list: List,
  show: Show,
};
