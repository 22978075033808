import React from 'react';
import { DateField, useLocale } from 'react-admin';

type Props = {
  source: string;
  showTime?: boolean;
  addLabel?: boolean;
  label?: string;
};

const LocaleDateField: React.FC<Props> = (props) => {
  const locale = useLocale();
  return <DateField locales={locale} {...props} />;
};

LocaleDateField.defaultProps = {
  addLabel: true,
};

export default LocaleDateField;
